<template>
  <div
    v-if="userStore.missingPassword"
    class="bg-blue w-full flex flex-col md:flex-row gap-x-4 gap-y-2 items-center py-2 px-6 text-white font-sans font-medium text-sm"
  >
    Vi mangler en adgangskode til din profil
    <NuxtLink
      :to="{
        name: 'usersetpassword',
      }"
    >
      <CommonButton
        bg="bg-white"
        text="text-blue"
        text-hover="hover:text-blue"
        bg-hover="hover:bg-gray-100"
        border="border-none"
      >
        {{ $t('setPassword') }}
      </CommonButton>
    </NuxtLink>
  </div>
</template>
<script setup lang="ts">
const userStore = useUserStore()
</script>
