<template>
  <div v-show="userStore.isLoggedIn && userStore.emailBelongsToAlrow">
    <p class="fixed top-10 right-0 mt-24 mr-5 inline-block z-[1000]">
      <CommonButton bg="bg-blue" text="text-white" @click="toggleDebugAds"
        >Toggle ads</CommonButton
      >
    </p>
  </div>
</template>

<script setup lang="ts">
const userStore = useUserStore()
const indexStore = useIndexStore()

const toggleDebugAds = () => {
  indexStore.debugAds = !indexStore.debugAds
}
</script>
