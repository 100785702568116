import { LocationQuery } from 'vue-router'

export type Notification = {
  message: string
  type: string
  showSubscribeButton?: boolean
  isPurchaseComplete?: boolean
}

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notifications: [] as Notification[],
  }),

  getters: {
    shouldShow(state) {
      return state.notifications.length > 0
    },
    grouped(state) {
      return groupBy(state.notifications, (msg) => msg.type)
    },
    shouldShowSubscribeButton(state) {
      return state.notifications.some(
        (notification) => notification.showSubscribeButton
      )
    },
  },

  actions: {
    add(notification: Notification) {
      if (
        !this.notifications.some(
          (n) =>
            n.message === notification.message && n.type === notification.type
        )
      ) {
        this.notifications.push(notification)
      }
    },
    clear() {
      const route = useRoute()
      const router = useRouter()

      const newQuery: LocationQuery = { ...route.query }

      let pushRoute = false
      if (newQuery['err']) {
        delete newQuery['err']
        pushRoute = true
      }
      if (newQuery['emailconfirmed']) {
        delete newQuery['emailconfirmed']
        pushRoute = true
      }
      if (newQuery['SSOResult']) {
        delete newQuery['SSOResult']
        pushRoute = true
      }
      if (newQuery['state']) {
        delete newQuery['state']
        pushRoute = true
      }

      if (pushRoute) {
        router.push({ query: newQuery })
      }
      this.$reset()
    },
  },
})

function groupBy(
  list: Notification[],
  keyGetter: (item: Notification) => string
) {
  const map = new Map()
  list.forEach((item) => {
    const key = keyGetter(item)
    const collection = map.get(key)
    if (!collection) {
      map.set(key, [item])
    } else {
      collection.push(item)
    }
  })
  return map
}
