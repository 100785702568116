<template>
  <div class="text-center">
    <div class="md:mx-10 mb-8">
      <div class="flex justify-center mb-10">
        <CheckCircleIcon />
      </div>
      <slot />
    </div>
    <div>
      <CommonButton size="xlarge" class="w-full" @click="emit('modal-close')">
        Ok
      </CommonButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import CheckCircleIcon from '~/assets/icons/check-circle.svg?component'

const emit = defineEmits(['modal-close'])
</script>
