import type { TempUser } from '~/stores/user'
import type { Subscription } from '~/typesAuto/apicore/v1'

const _MS_PER_DAY = 1000 * 60 * 60 * 24
const calcExpiryDays = (dateDifference: number) =>
  Math.floor(dateDifference / _MS_PER_DAY)

const getDateUTC = (date: Date) =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())

export function getSubscriptionBannerData(
  subscriptions: Subscription[],
  user?: TempUser
): { visible: false } | { visible: true; expiryDays: number } {
  if (
    !user?.clientId ||
    !user?.papers?.length ||
    !user?.isAdmin ||
    subscriptions.length == 0
  ) {
    return { visible: false }
  }

  const activeSubscriptions = subscriptions.filter(
    (subscription) => subscription.currentInvoice && subscription.aOpen !== 0
  )

  if (activeSubscriptions.length === 0) {
    return { visible: false }
  }

  const closestToExpirySubscription = activeSubscriptions.reduce(
    (previous, current) => {
      return new Date(previous.currentInvoice?.periodeEnd ?? 0) <
        new Date(current.currentInvoice?.periodeEnd ?? 0) &&
        previous.productType?.test === 0
        ? previous
        : current
    }
  )

  if (closestToExpirySubscription.productType?.test !== 1) {
    return { visible: false }
  }

  const closestToExpiryDate = new Date(
    closestToExpirySubscription.currentInvoice?.periodeEnd ?? 0
  )

  const utc1 = getDateUTC(new Date())
  const utc2 = getDateUTC(closestToExpiryDate)

  let expiryDays = calcExpiryDays(utc2 - utc1)

  // Hack:
  // Reduce remaining days by 16 to only show 14 days remaining in trial.
  // This logic goes into force on the 1st of November. Therefore, any trials
  // with expiry from the 2nd of December would be for 14 days
  if (closestToExpiryDate > new Date(2022, 11, 2)) {
    expiryDays = expiryDays - 16
  }

  if (expiryDays >= 0) {
    return { visible: true, expiryDays }
  }
  return { visible: false }
}
